import { styled } from '@mui/material/styles';
import { Box, DialogContent } from '@mui/material';
import robinet from '../../assets/images/robinet.jpg';

export const StyledBox = styled(Box)(() => ({
    '&.google': {
        height: '300px',
        width: '100%',
        padding: '0px',
        '.leaflet-marker-icon': {
            backgroundColor: 'transparent',
        }
    },
    '&.contact': {
        scrollMargin: '80px',
        '@media (max-width: 500px)': {
            scrollMargin: '116px',
        },
        '@media (max-width: 430px)': {
            scrollMargin: '162px',
        },
        '#contact-form': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
            backgroundColor: 'transparent',
            margin: '0px',
            width: '900px',
            'h2': {
                color: '#274093',
                textAlign: 'center',
                fontSize: '40px',
            },
            '.MuiInputBase-multiline': {
                backgroundColor: 'white',
                height: '150px',
            },
            '#name, #email, #phone': {
                backgroundColor: 'white',
            },
            '.submit': {
                backgroudColor: '#274093',
            }
        },
        backgroundColor: '#E4E7F2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
    },
    '&.partenaire': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0px',
        'h2': {
            fontSize: '40px',
            marginBottom: '30px',
            '@media (max-width: 460px)': {
                textAlign: 'center',
            }
        },
        '.imgs': {
            width: '95%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '@media (max-width: 1250px)': {
                flexWrap: 'wrap',
                justifyContent: 'center',
                'img': {
                    marginRight: '10px',
                },
            },
            '@media (max-width: 450px)': {
                'img': {
                    marginBottom: '10px',
                }
            }
        }
    },
    '&.top': {
        scrollMargin: '80px',
        marginTop: '80px',
        fitler: `blur(4px)`,
        backgroundImage: `linear-gradient(to bottom, rgba(39, 64, 147, 0.73), rgba(228, 231, 242, 0.52)), url(${robinet})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '500px',
        position: 'relative',
        '@media (max-width: 1100px)': {
            height: '350px',
        },
        '@media (max-width: 550px)': {
            height: '250px',
        },
        '@media (max-width: 500px)': {
            scrollMargin: '116px',
            marginTop: '116px',
        },
        '@media (max-width: 430px)': {
            scrollMargin: '162px',
            marginTop: '162px',
        },
        '.top-content': {
            position: 'absolute',
            top: '20%',
            left: '10%',
            color: 'white',
            '@media (max-width: 1100px)': {
                left: '20px',
            },
            'h1': {
                fontSize: '70px',
                fontWeight: '700',
                margin: '0px',

                '@media (max-width: 1100px)': {
                    fontSize: '50px',
                },
                '@media (max-width: 700px)': {
                    fontSize: '40px',
                },
                '@media (max-width: 550px)': {
                    fontSize: '30px',
                },
                '@media (max-width: 350px)': {
                    fontSize: '25px'
                }
            },
            'h2': {
                fontSize: '30px',
                margin: '0px',
                '@media (max-width: 500px)': {
                    fontSize: '20px'
                },
            }
        }
    },
    '&.services': {
        '.presentation': {
            paddingBottom: '15px',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            '@media (max-width: 750px)': {
                padding: '10px',
            },

            '.left': {
                '@media (max-width: 1200px)': {
                    display: 'none',
                }
            },

            '.right': {
                marginLeft: '50px',
                '@media (max-width: 750px)': {
                    marginLeft: '0px',
                    marginTop: '5px',
                },
                '.presTop': {
                    '@media (max-width: 1200px)': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '@media (max-width: 750px)': {
                        flexDirection: 'column',
                    },
                    '.imgleft': {
                        display: 'none',
                        '@media (max-width: 1200px)': {
                            display: 'block',
                        },
                        '@media (max-width: 750px)': {
                            'img': {
                                height: '450px',
                            },
                        },
                        '@media (max-width: 500px)': {
                            'img': {
                                height: '350px',
                            },
                        },
                    },
                    '.txtRight': {
                        '.h3': {
                            fontSize: '30px',
                            marginBottom: '20px',
                        },
                        '.p': {
                            width: '800px',
                            marginBottom: '30px',

                            '@media (max-width: 1200px)': {
                                width: '500px',
                            },

                            '@media (max-width: 750px)': {
                                width: '100%',
                                textAlign: 'justify',
                            },
                        },
                        '.txt': {
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',

                            '.p': {
                                fontSize: '20px',
                                fontWeight: '700',
                                width: '150px',
                                marginRight: '30px',

                                '@media (max-width: 530px)': {
                                    width: '100%',
                                }
                            }
                        },
                    },
                },
                '.infos': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '@media (max-width: 1200px)': {
                        flexDirection: 'column',
                    },
                    '.urgence, .devi, .ctc': {
                        boxShadow: '10px 5px 5px #7888BC',
                        width: '250px',
                        height: '250px',
                        backgroundColor: '#274093',
                        color: 'white',
                        marginRight: '50px',
                        padding: '15px',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '@media (max-width: 1350px)': {
                            width: '200px',
                        },
                        '@media (max-width: 1200px)': {
                            width: '90%',
                            height: 'auto',
                            marginbottom: '5px',
                        },
                        '@media (max-width: 750px)': {
                            marginRight: '0px',
                        },
                        '.body': {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    }
                }
            }
        },
        '.devis': {
            backgroundImage: `linear-gradient(to right, rgba(39, 64, 147, 0.73), rgba(120, 136, 188, 0.52))`,
            height: '250px',
            textAlign: 'center',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '.h2': {
                marginTop: '0px',
                marginBottom: '20px',
            },
            '.text': {
                width: '75%'
            },
            '@media (max-width: 700px)': {
                height: 'auto',
                padding: '5px 0px',
            }
        },
        '.nos-services': {
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '.h2': {
                marginTop: '15px',
                '@media (max-width: 950px)': {
                    textAlign: 'center',
                    fontSize: '50px',
                },
            },
            '.title': {
                marginTop: '30px',
                textAlign: 'center',
                width: '60%',
            },
            '.listing': {
                width: '70%',
                height: '800px',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                marginTop: '50px',
                '@media (max-width: 1500px)': {
                    width: '90%',
                },
                '@media (max-width: 1167px)': {
                    width: '98%',
                },
                '@media (max-width: 1072px)': {
                    width: '70%',
                    height: 'auto',
                },
                '@media (max-width: 1000px)': {
                    width: '100%',
                },
                '@media (max-width: 700px)': {
                    justifyContent: 'center',
                },
                '.element': {
                    width: '350px',
                    height: '200px',
                    '@media (max-width: 700px)': {
                        height: 'auto',
                        marginBottom: '10px',
                    },
                    '.subtitle': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        fontWeight: '700',
                        fontSize: '20px',
                    },
                    '.body': {
                        marginLeft: '30px',
                        marginTop: '15px',
                    }
                }
            }
        }
    }
}))

export const StyledDialogContent = styled(DialogContent)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.iconContent': {
        border: '5px solid green',
        borderRadius: '100%',
        width: '150px',
        height: '150px',
        position: 'relative',

        '.icon': {
            color: 'green',
            fontSize: '80px',
            position: 'absolute',
            top: '25%',
            left: '25%'
        }
    }
}));
