import React from 'react';
import logo from '../../assets/images/logo.png';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import {
    ContactContainer,
    HeaderContainer,
    PhoneButton,
    StyledButton,
    StyledImg,
} from './header.style';


function Header({ contactRef, topRef }: any) {
    const goToContact = () => contactRef.current.scrollIntoView({
        behavior: 'smooth'
    });

    const goToTop = () => topRef.current.scrollIntoView({
        behavior: 'smooth'
    });

    return (
        <HeaderContainer>
            <StyledImg src={logo} alt="Logo" onClick={goToTop} />
            <ContactContainer>
                <StyledButton
                    variant="outlined"
                    startIcon={<MailOutlineIcon />}
                    design="secondary"
                    onClick={goToContact}
                >
                    Contactez nous
                </StyledButton>
                <PhoneButton href='tel:0669322944'><PhoneIcon /> 06 69 32 29 44</PhoneButton>
            </ContactContainer>
        </HeaderContainer>
    )
}

export default Header;
