import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material';

export const FooterContainer = styled(Box)(() => ({
    alignItems: 'center',
    backgroundColor: '#4258A1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

export const StyledBox = styled(Box)(() => ({
    '&.bottom': {
        borderTop: '1px solid #ffffff',
        justifyContent: 'center',
        textAlign: 'center',
        width: '50%',
    },
    '&.top': {
        width: '75%',
    },
    '.link, .contact': {
        display: 'flex',
        flexDirection: 'column',
    },
    '.link': {
        '.link1, .link2': {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        '.link1': {
            marginBottom: '10px',
        },
        alignItems: 'center',
    },
    '.contact': {
        '.phone, .email': {
            '.MuiSvgIcon-root': {
                marginRight: '20px',
            },
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            width: '250px',
        },
        '.phone': {
            marginBottom: '15px',
        },
        '@media (max-width: 790px)': {
            marginTop: '10px',
        },
    },
    alignItems: 'center',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    '@media (max-width: 790px)': {
        flexDirection: 'column',
    },
}));

export const StyledImg = styled('img')(() => ({
    height: '200px',
    width: '200px',
}));