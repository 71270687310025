import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface MentionsLegalesProps {
    open: boolean,
    handleClose: () => void,
}

function MentionsLegales({ open, handleClose }: MentionsLegalesProps) {
    return (
        <Dialog open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                Mentions Légales
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <List>
                    <ListItem>
                        <ListItemText>
                            <b>Raison sociale :</b> PCI Plomberie
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>Forme juridique  :</b> Entreprise individuelle (auto entrepreneur)
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>Adresse :</b> 300 avenue de Muret 31300 Toulouse
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>Gérant :</b> Charles PRANDO
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>Adresse mail :</b> pciplomberie1@gmail.com
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>Téléphone :</b> 06 69 32 29 4
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>Raison sociale :</b> PCI Plomberie
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>SIRET :</b> 90367620300016
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <b>L’hébergement </b> ********
                        </ListItemText>
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default MentionsLegales;
