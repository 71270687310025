import { styled } from '@mui/material/styles';
import { Box, Button } from "@mui/material";

interface ButtonProps {
    design?: 'primary' | 'secondary';
}

export const HeaderContainer = styled(Box)(() => ({
    alignItems: 'center',
    backgroundColor: 'whitesmoke',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '999',
    '@media (max-width: 500px)': {
        flexDirection: 'column',
    },
}));

export const ContactContainer = styled(Box)(() => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '400px',
    marginRight: '15px',

    '@media (max-width: 500px)': {
        marginRight: '0px',
    },

    '@media (max-width: 430px)': {
        flexDirection: 'column',
    },
}));

export const StyledImg = styled('img')(() => ({
    height: '70px',
    width: '70px',
    '&:hover': {
        cursor: 'pointer',
    }
}));

export const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'design',
})(({ design }: ButtonProps) => ({
    borderRadius: '15px',
    ...(design === 'primary' ? {
        backgroundColor: '#274093',
    } : {
        borderBlockColor: '#274093',
        color: '#274093',
    }),
}));

export const PhoneButton = styled('a')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white !important',
    backgroundColor: '#274093',
    padding: '5px 15px',
    borderRadius: '15px',
    border: '1px solid #274093',
    '&:hover': {
        backgroundColor: 'transparent',
        color: '#274093 !important',
        textDecoration: 'none',
    },
    width: '158px',
    '@media (max-width: 430px)': {
        marginTop: '10px',
    },
}));
