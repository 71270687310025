import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const TypographyTitle = styled(Typography)(() => ({
    color: '#274093',
    marginTop: '20px',
}));

export const StyledTypography = styled(Typography)(() => ({
    textAlign: 'justify',
    padding: '5px',
}));
