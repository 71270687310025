import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTypography, TypographyTitle } from './confidentialite.style';

interface ConfidentialiteProps {
    open: boolean,
    handleClose: () => void,
}

function Confidentialite({ open, handleClose }: ConfidentialiteProps) {
    return (
        <Dialog open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                Confidentialité
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <TypographyTitle variant='h4'>
                    1. Collecte de l'information
                </TypographyTitle>
                <StyledTypography variant='body1'>
                    Nous récoltons uniquement les données provenant du formulaire de contact.
                </StyledTypography>
                <TypographyTitle variant='h4'>
                    2. Utilisation des informations
                </TypographyTitle>
                <StyledTypography variant='body1'>
                    Les données que vous avez transmise (Nom/Prénom et adresse email) seront utilisées afin
                    de prendre contact avec vous et répondre à vos questions.
                </StyledTypography>
                <TypographyTitle variant='h4'>
                    3. Confidentialité du formulaire de contact
                </TypographyTitle>
                <StyledTypography variant='body1'>
                    Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre
                    société pour n’importe quelle raison, sans votre consentement, en dehors de ce qui est nécessaire
                    pour répondre à une demande et / ou une transaction, comme par exemple pour expédier une commande.
                </StyledTypography>
                <TypographyTitle variant='h4'>
                    4. Divulgation à des tiers
                </TypographyTitle>
                <StyledTypography variant='body1'>
                    Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à
                    des tiers.
                </StyledTypography>
                <TypographyTitle variant='h4'>
                    5. Consentement
                </TypographyTitle>
                <StyledTypography variant='body1'>
                    En utilisant notre site, vous consentez à notre politique de confidentialité.
                </StyledTypography>
            </DialogContent>
        </Dialog>
    )
}

export default Confidentialite;
