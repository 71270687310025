import React, { useRef } from 'react';
import './App.css';
import Footer from './components/Footer/footer';
import Header from './components/Header/header';
import Content from './components/Content/content';

function App() {
  const contactRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div className="container">
      <Header contactRef={contactRef} topRef={topRef} />
      <Content contactRef={contactRef} topRef={topRef} />
      <Footer />
    </div>
  );
}

export default App;
