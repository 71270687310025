import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Typography } from '@mui/material';
import { FooterContainer, StyledBox, StyledImg } from './ footer.style';
import MentionsLegales from '../MentionsLegales/mentionsLegales';
import Confidentialite from '../Confidentialite/confidentialite';

function Footer() {
    const [isOpen, setIsOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
    }

    const click = () => {
        setOpen(true);
    }

    return (
        <>
            <MentionsLegales open={isOpen} handleClose={() => setIsOpen(false)} />
            <Confidentialite open={open} handleClose={() => setOpen(false)} />
            <FooterContainer>
                <StyledBox className="top">
                    <StyledImg src={logo} alt="Logo" />
                    <Box className="link">
                        <Typography className='link1' onClick={handleClick}>Mentions légales</Typography>
                        <Typography className='link2' onClick={click}>Confidentialité</Typography>
                    </Box>
                    <Box className="contact">
                        <Typography className='phone'><PhoneIcon /> 06 69 32 29 44</Typography>
                        <Typography className='email'><MailOutlineIcon /> pciplomberie1@gmail.com</Typography>
                    </Box>
                </StyledBox>
                <StyledBox className="bottom">
                    <Typography variant="subtitle1">Copyright @ PCI Plomberie</Typography>
                </StyledBox>
            </FooterContainer>
        </>
    );
}

export default Footer;
