import React, { ReactElement, Ref, forwardRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import ajmultigest from '../../assets/images/partenaires/ajmultigest.png';
import axa from '../../assets/images/partenaires/axa.png';
import elyade from '../../assets/images/partenaires/elyade.png';
import ete from '../../assets/images/partenaires/ete.jpg';
import foncia from '../../assets/images/partenaires/foncia.png';
import charles from '../../assets/images/charles.jpg';
import L from 'leaflet';
import { StyledBox, StyledDialogContent } from './content.style';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import 'leaflet/dist/leaflet.css';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography,
    Slide,
} from '@mui/material';
import emailjs from '@emailjs/browser';
import { TransitionProps } from '@mui/material/transitions';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Content({ contactRef, topRef }: any) {
    const [open, setOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const position: [number, number] = [43.5915121019092, 1.4337953209268375];

    const handleDragStart = (e: any) => e.preventDefault();

    const items = [
        <img src={ajmultigest} onDragStart={handleDragStart} role='presentation' alt='ajmultigest' />,
        <img src={axa} onDragStart={handleDragStart} role='presentation' alt='axa' />,
        <img src={elyade} onDragStart={handleDragStart} role='presentation' alt='elyade' />,
        <img src={ete} onDragStart={handleDragStart} role='presentation' alt='ete' />,
        <img src={foncia} onDragStart={handleDragStart} role='presentation' alt='foncia' />,
    ];

    const sendEmail = (e: any) => {
        e.preventDefault();
        emailjs.sendForm('service_rcolcoz', 'template_ru4x7ta', e.target, 'guzef4kS52s3zZgRY');
        setOpen(true);
        setIsChecked(false);
        e.target.reset();
    };

    const handleClose = () => setOpen(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setIsChecked(event.target.checked);

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>Votre message à bien été envoyé !</DialogTitle>
                <StyledDialogContent>
                    <div className='iconContent'>
                        <CheckOutlinedIcon className="icon" />
                    </div>
                </StyledDialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>
            <StyledBox className="top" ref={topRef}>
                <div className='top-content'>
                    <h1>Votre plombier à Toulouse<br></br>Dépannage et rénovation plomberie</h1>
                    <h2>Charles - COMPAGNON DU DEVOIR</h2>
                    <span className='sousText'>Disponible 24h/24 - 7j/7</span>
                </div>
            </StyledBox>
            <StyledBox className="services">
                <Box className='presentation'>
                    <Box className='left'>
                        <img src={charles} alt='charles' />
                    </Box>
                    <Box className='right'>
                        <Box className='presTop'>
                            <Box className='imgleft'>
                                <img src={charles} alt='charles' />
                            </Box>
                            <Box className="txtRight">
                                <Typography variant='h3' className='h3'>Plombier Toulouse : Mes valeurs et engagements</Typography>
                                <Typography variant='body1' className='p'>
                                    Dans un rayon de 20km autour de Toulouse,
                                    <b> pour tout dépannage, réparation et installation en plomberie sanitaire. </b>
                                    Réalisation par un artisan plombier <b>expérimenté et qualifé à Toulouse (31) </b>
                                    avec un matériel de dernière génération.
                                </Typography>
                                <Box className='txt'>
                                    <Typography className='p'>Disponibilité 24H/24 et 7j/7, devis gratuit et détaillé</Typography>
                                    <Typography className='p'>Diagnostic plomberie sans surcout et conseils</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='infos'>
                            <Box className='urgence'>
                                <Typography variant='h5'><HomeOutlinedIcon /> Votre plombier Toulousain en Urgence</Typography>
                                <Typography variant='body1' className='body'>
                                    Intervention dans l'heure garantie 24h/24 et 7j/7 pour toutes interventions
                                    de plomberie à Toulouse et ses alentours (20km). N'hésitez plus appelez moi !
                                </Typography>
                            </Box>
                            <Box className='devi'>
                                <Typography variant='h5'><DomainAddOutlinedIcon /> Devis Gratuit</Typography>
                                <Typography variant='body1' className='body'>
                                    Je réalise les devis gratuitement. Appelez moi !
                                </Typography>
                            </Box>
                            <Box className='ctc'>
                                <Typography variant='h5'><CalendarMonthOutlinedIcon /> Contactez moi 24h/24 7j/7</Typography>
                                <Typography variant='body1' className='body'>
                                    Appelez moi au 06 69 32 29 44 ou par mail. Interventions dans l'heure à Toulouse et dans ses environs.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                 <Box className='devis'>
                    <Typography variant='h2' className='h2'>Devis gratuits</Typography>
                    <Typography variant='body1' className='text'>
                        Pour rappel, toutes les interventions sont précédées d'un devis gratuit.
                        Par la suite, si le dépannage est accepté, je mettrai mon savoir faire à votre service pour résoudre votre problème.
                        <br/>
                        <br/>
                        <br/>
                        Gage de qualité, toutes mes interventions sont <b>GARANTIES 12 MOIS</b>
                    </Typography>
                </Box>
                <Box className='nos-services'>
                    <Typography variant='h2' className='h2'>
                        Mes services plomberie dépannage
                    </Typography>
                    <Typography variant='subtitle1' className='title'>
                        Votre plombier vous propose des prestations de qualité, sa connaissance des diverses marques existantes lui permettra
                        de vous conseiller afin que le meilleur choix soit fait pour vos installations.
                    </Typography>
                    <Box className="listing">
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Diagnostic et réparation fuite d'eau.
                            </Typography>
                            <Typography variant='body1' className="body">
                                robinetterie, canalisation, WC, évier, lavabo, baignoire, douche
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Débouchage toutes canalisations et sanitaires.
                            </Typography>
                            <Typography variant='body1' className="body">
                                débouchage de WC , sani-broyeurs, evier bouché, baignoire et douche
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Réparation et remplacement plomberie.
                            </Typography>
                            <Typography variant='body1' className="body">
                                siphons, mitigeurs, joints, tuyaux et raccordements à remplacer,
                                réparation chasse d’eau qui fuit et tout problème sur plomberie sanitaire
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Dépannage et réparation chauffage.
                            </Typography>
                            <Typography variant='body1' className="body">
                                réparation chaudière en panne, installation chaudière, réparation ou remplacement
                                chauffe-eau ( ballon eau-chaude)
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Dépannage entretien robinetterie et canalisation.
                            </Typography>
                            <Typography variant='body1' className="body">
                                pour un bonfonctionnement de vos éléments sanitaires, votre plombier à Toulouse assure le remplacement des joints, détartrage des
                                canalisations, nettoyage et tout conseil pour maintenir votre plomberie en excellent état.
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Détartrage et entretien chauffage.
                            </Typography>
                            <Typography variant='body1' className="body">
                                chauffe-eau électrique, radiateurs
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Installation chauffe-eau.
                            </Typography>
                            <Typography variant='body1' className="body">
                                pose et dépose d’un chauffe-eau électrique
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Remplacement pose de robinetterie.
                            </Typography>
                            <Typography variant='body1' className="body">
                                mitigeurs, robinets thermostatiques
                            </Typography>
                        </Box>
                        <Box className="element">
                            <Typography variant='subtitle2' className='subtitle'>
                                <CheckCircleIcon style={{ color: '#937A27', marginRight: '5px' }} /> Conseils et mise aux normes.
                            </Typography>
                            <Typography variant='body1' className="body">
                                canalisations et toute plomberie sanitaire selon les réglementations en vigueur.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledBox>
            <StyledBox className="partenaire">
                <h2>Ils nous font confiance !</h2>
                <Box className='imgs'>
                    {items}
                </Box>
            </StyledBox>
            <StyledBox ref={contactRef} id="contact" className="contact">
                <form id='contact-form' onSubmit={sendEmail}>
                    <h2>Formulaire de contact !</h2>
                    <TextField
                        fullWidth
                        required
                        id='name'
                        label='Nom et Prénom'
                        name='name'
                        margin='normal'
                    />
                    <TextField
                        fullWidth
                        required
                        id='email'
                        label='Email'
                        name='email'
                        margin='normal'
                    />
                    <TextField
                        fullWidth
                        required
                        id='phone'
                        label='Téléphone'
                        name='phone'
                        margin='normal'
                    />
                    <TextField
                        fullWidth
                        required
                        id='message'
                        label='Message'
                        name='message'
                        margin='normal'
                        multiline
                        maxRows="5"
                    />
                    <FormControlLabel required control={<Checkbox checked={isChecked} onChange={handleChange} />} label="J'accepte les conditions générales d'utilisation." />
                    <Button className='submit' type='submit' variant='contained'>Envoyer</Button>
                </form>
            </StyledBox>
            <StyledBox className="google">
                <MapContainer
                    style={{ height: "100%", width: "100%" }}
                    center={position}
                    zoom={19}
                    zoomControl={false}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position} />
                </MapContainer>
            </StyledBox>
        </>
    )
}

export default Content;
